import "../css/app.css";
import "lazysizes";
import Alpine from "alpinejs";
import Splide from "@splidejs/splide";
// import GLightbox from 'glightbox';

// import './cookie.js';

console.log("Hello World");

// Alpine.data('hero', () => ({
//   init() {
//     this.setViewHeight();
//     window.addEventListener('resize', () => {
//       this.setViewHeight()
//     })
//   },
//   setViewHeight: function () {
//     const vh = window.innerHeight * 0.01;
//     document.documentElement.style.setProperty('--vh', `${vh}px`);
//   },
// }));

Alpine.start();

// Splide
document.addEventListener("DOMContentLoaded", function() {
  const slider = document.getElementsByClassName("splide-slider");
  for (let i = 0; i < slider.length; i++) {
    new Splide(slider[i], {
      type: "loop",
      lazyLoad: "nearby",
      rewind: true,
      padding: "10rem",
      perPage: 4,
      gap: "2em",
      pagination: false,
      breakpoints: {
        1500: {
          perPage: 3
        },
        1024: {
          perPage: 2
        },
        820: {
          perPage: 1
        },
        640: {
          perPage: 1,
          padding: "5em",
          gap: "2em"
        }
      }
    }).mount();
  }

  const highlights = document.getElementsByClassName("splide-highlights");
  for (let i = 0; i < highlights.length; i++) {
    new Splide(highlights[i], {
      type: "slide",
      lazyLoad: "nearby",
      perPage: 5,
      gap: "1.5em",
      pagination: false,
      breakpoints: {
        1280: {
          perPage: 4
        },
        900: {
          perPage: 3
        },
        680: {
          perPage: 2
        },
        480: {
          perPage: 1
        }
      }
    }).mount();
  }

  const galleries = document.getElementsByClassName("splide-gallery");
  for (let i = 0; i < galleries.length; i++) {
    new Splide(galleries[i], {
      lazyLoad: "nearby"
    }).mount();
  }
});

// if (document.querySelector('.glightbox')) {
//   GLightbox({
//     selector: '.glightbox',
//     openEffect: 'fade',
//   });
// }

// if (document.querySelector('.glightboxSingle')) {
//   GLightbox({
//     selector: '.glightboxSingle',
//     openEffect: 'fade',
//   });
// }
